/*
 * @Descripttion:
 * @version:
 * @Author: king
 * @Date: 2023-09-11 14:14:20
 * @LastEditors: wei
 * @LastEditTime: 2023-11-11 15:17:25
 */
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';
const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: '',
    avatar: '',
    username: '',
    id: '',
    buttons: [],
    keepAlive: [],
    menu: [],
  },
  getters: {
    banner_type() {
      return {
        1: '首页轮播图',
        2: '商城轮播图',
        3: '电子说明书轮播图',
        4: '新闻列表轮播图',
      };
    },
    order_status() {
      return {
        0: '待付款',
        1: '待发货/待自提',
        2: '待收货/已自提',
        3: '待评价',
        4: '已取消',
        5: '售后退款中',
        6: '已完成',
        7: '售后完成',
        8: '部分退款中',
      };
    },
  },
  mutations: {
    login(state, payload) {
      state.token = payload.token;
    },
    logout(state) {
      state.token = '';
    },
    setAdminInfo(state, payload) {
      state.avatar = payload.avatar;
      state.username = payload.username;
      state.id = payload.id;
    },
    setButtons(state, payload) {
      state.buttons = payload;
    },
    setKeepAlive(state, alive) {
      state.keepAlive = alive;
    },
    setMenu(state, menu) {
      state.menu = menu;
    },
  },
  actions: {
    setKeepAlive(ctx, keepalice) {
      ctx.commit('setKeepAlive', keepalice);
    },
    setMenu(state, menu) {
      state.commit('setMenu', menu);
    },
  },
  modules: {},
  plugins: [
    createPersistedState({
      key: 'token',
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});
